import { scbAxiosInstance, axiosInstance } from "../services";
import { loaderStore } from "../stores";

export const getHybridBid = async (params = {}) => {
    const loaderState = loaderStore();
    const resp = await axiosInstance.get("/api/bid_details", {params})
    .then(res => {
        loaderState.setLoading(false, []);
        return res;
    }).catch((e) => {
        loaderState.setLoading(false, []);
        return e.response
    })
    return resp;
}

export const getOpenBid = async (params = {}) => {
    params.retro_bid = false;
    let url = params.type === 'current_bid' ? "api/external_service/get_current_bids.json" : "api/external_service/get_user_archived_bids.json"
    const resp = await scbAxiosInstance.get(url, {params})
    .then(res => {
        return res;
    }).catch((e) => {
        return e.response
    })
    return resp;
}
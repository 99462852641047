  import CountDown from "../countDown/index.vue";
  import lotImage from "@/../assets/images/Box.svg";
  import { getOpenBid, getHybridBid } from "../../Actions/BidActions";
  import { formattedBids, formatCurrency} from "../../misc/formatter";
  import { isMobile} from "../../misc/index";
  import { useRoute, useRouter } from "vue-router";
  import configuration from "../../config.json";
  import { useMetaStore, useAuth } from "../../stores";
  import { reactive, computed } from "vue";
  export default {
    data() {
      return {
          activePage: 1,
          totalCount: 0,
          totalPages: 1,
          bids: [],
          type: 'Hybrid',
          activeTab: 'Current'
        }
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const authStore = useAuth();
      const metaStore = useMetaStore();
      const marketplaceContent = computed(() => metaStore.marketplaceContent)
      const handleUpdate = (val) => {
        const { bidType = "Current" } = router.currentRoute.value.query;
        router.push(router.currentRoute.value.path + `?mode=${val}&bidType=${bidType}&page=${1}`)
      }
      const handlePageUpdate = (page) => {
        const { mode = "Hybrid", bidType = "Current" } = router.currentRoute.value.query;
        router.push(router.currentRoute.value.path + `?mode=${mode}&bidType=${bidType}&page=${page}`)
      }


      const user = computed(() => authStore.user);

      const shouldShowContent = computed(() => {
        return metaStore.marketplaceContent !== 'private' || user?.value?.authentication_token;
      });

      const userSignin = () => {
        if (user?.value?.authentication_token) {
          authStore.signOut("");
        } else {
          authStore.setLoginDialog(true);
        }
      };

      let types = ['Hybrid', 'Open']

      return {
        lotImage,
        handlePageUpdate,
        formatCurrency,
        types,
        handleUpdate,
        hasOpen: configuration.Lots.Open.enabled,
        showMRP: configuration.showMRP,
        amountLabel: configuration.amountLabel,
        route,
        shouldShowContent,
        userSignin,
        marketplaceContent
      }
    },
    components: {
      CountDown
    },
    watch: {
      '$router': {
        handler: function(path) {
          const pageQuery = path.currentRoute.value.query
          this.type = pageQuery?.mode ? pageQuery.mode : 'Hybrid';
          this.activeTab = pageQuery?.bidType ? pageQuery.bidType : 'Current';
         },
        deep: true,
        immediate: true
      },
      activeTab() {
        this.totalPages = null;
        this.activePage = 1;
        this.fetchBids()
      },
      type() {
        this.activePage = 1;
        this.totalPages = null;
        this.fetchBids()
      },
      activePage() {
        window.scrollTo(0,0)
        this.fetchBids()
      }
    },
    computed: {
      getAllPages() {
        return this.totalPages;
      }
    },
    methods: {
      isMobile: isMobile,
      replaceLotByDefault(e) {
        e.target.src = lotImage
      },
      toggleExpanded(value) {
        this.activeTab = value
        const { path, query} = this.$router.currentRoute.value;
        let url = this.$router.currentRoute.value.path + `?mode=${query.mode ?? 'Hybrid'}&bidType=${value}`
        this.$router.push(url)
      },
      redirectToDetails(bid) {
        const metaStore = useMetaStore();
        const metaContent = metaStore.marketplaceContent;
        const marketplaceName = metaStore.marketplaceName;
        let baseUrl = metaContent === 'private' ? `/${marketplaceName}` : '';
        
        function extractProductName(lotName) {
          const parts = lotName.split('||');
          let extractedName;
          if (parts.length > 1) {
            extractedName = `${parts[0]} || ${parts[1]}`.trim();
          }else {
              extractedName = lotName.trim();
          }
          return extractedName.replace(/\s+/g, '-');
        }
        const extractedProductName = extractProductName(bid.title);
        let url = bid?.bid_id 
        ? `${baseUrl}/product_detail/${encodeURIComponent(extractedProductName)}/${bid.lot_publish_id}?bid_id=${bid?.bid_id}` 
        : `${baseUrl}/product_detail/${encodeURIComponent(extractedProductName)}/${bid.lot_publish_id}`;
        
        // let url = bid?.bid_id ? `/product_detail/${extractedProductName}/${bid.lot_publish_id}?bid_id=${bid?.bid_id}` : `/product_detail/${bid.lot_publish_id}`
        this.$router.push(url);
      },
      getImgUrl(images = []) {
        return images.length > 0 ? images[0] : "https://s3.ap-south-1.amazonaws.com/uat-blubirch/images/attachments/000/202/042/medium/open-uri20230418-3633754-gqbhka?1681816971";
      },
      getHybridBids() {
        getHybridBid({
          "page": this.activePage,
          "bidding_mode": 'hybrid',
          "per_page": 12,
          "sort": "-updated_at",
          "type": this.activeTab === "Current" ? 'current_bid' : 'archived_bid',
        }).then(res => {
          this.bids = formattedBids(res.data?.bid_details) || [];
          this.totalCount = res?.data?.meta?.total_count
          this.totalPages = parseInt(res?.data?.meta?.total_pages) || 1;
        })
      },
      getOpenBidsData() {
        getOpenBid({
          "type": this.activeTab === "Current" ? 'current_bid' : 'archived_bid',
          "sort": "-created_at",
          "page_number": this.activePage,
          "page_size": 12,
        }).then(res => {
          this.bids = formattedBids(res.data?.external_service) || [];
          this.totalCount = res?.data?.meta?.total
          this.totalPages = parseInt(parseInt(res?.data?.meta?.total / 12) + ((res?.data?.meta?.total % 12) > 0 ? 1 : 0)) || 1;
        })
      },
      fetchBids() {
        this.type !== 'Open' ? this.getHybridBids() : this.getOpenBidsData();
      }
    },
    mounted() {
      if(this.$router.currentRoute?.value?.query?.mode) {
        this.type = this.$router.currentRoute?.value?.query?.mode
      }
      if(this.$router.currentRoute?.value?.query?.bidType) {
        this.activeTab = this.$router.currentRoute?.value?.query?.bidType
      }

      if(this.$router.currentRoute?.value?.query?.page) {
        this.activePage = parseInt(this.$router.currentRoute?.value?.query?.page)
      }
      this.fetchBids()
    },
}